<template>
  <div>
    <div class="post-section">
      <form
        @submit="uploadPost"
        method="post"
        id="postData"
        enctype="multipart/form-data"
      >
        <input
          class="form-control"
          type="text"
          placeholder="Write here to post something"
          v-model="searchKey"
          name="searchKey"
        />
        <div class="from-wrapper">
          <label class="form-file-box">
            <img src="../assets/camera.svg" alt="" />
            <input type="file" name="picture" @change="onImageChange" />
          </label>
          <button class="btn" type="submit" :disabled="disabled">Post</button>
        </div>
      </form>
    </div>

    <div class="this-week">
      <div class="tropy-01">
        <div class="trophy-02"><img src="../assets/trophy-icon.svg" /></div>
        <div class="ths-wk">This Week</div>

        <div class="weekly-winners">
          <div class="history-record community">
            <div class="game-img"><img src="../assets/pubg-thumb.png" /></div>
            <div class="history-game-title com-title">
              <h4>Darkfall</h4>
              <h5>Grandmaster</h5>
            </div>

            <div class="redeem-btn-01 commu-03"><h3>1st Rank</h3></div>
            <!-- <div class="history-play-button"> <a href="#"> Redeem</a> </div> -->
          </div>
          <div class="history-record no-margin community">
            <div class="game-img"><img src="../assets/pubg-thumb.png" /></div>
            <div class="history-game-title com-title">
              <h4>Endstand</h4>
              <h5>Grandmaster</h5>
            </div>

            <div class="redeem-btn-01 commu-03"><h3>2nd Rank</h3></div>
          </div>
          <div class="history-record no-margin community">
            <div class="game-img"><img src="../assets/pubg-thumb.png" /></div>
            <div class="history-game-title com-title">
              <h4>Efrafa</h4>
              <h5>Grandmaster</h5>
            </div>

            <div class="redeem-btn-01 commu-03"><h3>3rd Rank</h3></div>
          </div>
        </div>
      </div>
    </div>

    <div class="tounament-enroll">
      <div class="tt-01">
        <div class="lft-01">
          <img src="../assets/trophy-small-icon.svg" /> Vivo Tournament
        </div>
        <div class="mr-01-btn">
          <button type="button">Free</button>
        </div>
      </div>

      <div class="tournament-banner">
        <img src="../assets/pub-g-banner.png" />
      </div>

      <div class="tournament-date">
        <div class="line-01"></div>
        <div class="st-date">
          <h3>Start Date</h3>
          <h4>19 Jan 2020</h4>
        </div>
        <div class="end-date">
          <h3>Start Date</h3>
          <h4>19 Jan 2020</h4>
        </div>
      </div>

      <div class="enroll-btn">
        <button
          type="button"
          onclick="showModal('enrlModal')"
          href="JavaScript:void(0);"
        >
          Enroll Now
        </button>
      </div>

      <div class="terms-coomu">
        <ul>
          <li>Game Rules, Terms and Conditions</li>
          <li>
            <button type="button">
              <img src="../assets/go-arrow-right.svg" />
            </button>
          </li>
        </ul>
      </div>
    </div>

    <div class="status-post-section" v-for="post in posts" :key="post.id">
      <div class="status-contents">
        <div class="status-top-box">
          <img src="../assets/user-avatar.svg" alt="" />
          <h3>
            {{ post.name }} <span>{{ post.commenting_date }}</span>
          </h3>
        </div>
        <p>{{ post.message }}</p>
        <!--                <p>Common Loon (In French, Plongeon Huard) with a green crab snack. Tantallon, Nova Scotia, Canada.</p>-->
      </div>
      <div class="status-share-box">
        <a href="#"
          ><img src="../assets/like-icon.svg" alt="" /><span> 2</span>
        </a>
        <a href="#"
          ><img src="../assets/comment-icon.svg" alt="" /><span> 2</span></a
        >
        <a href="#"
          ><img src="../assets/share-icon-grey.svg" alt="" /><span> 2</span></a
        >
      </div>

      <div class="status-top-box p-15">
        <img src="../assets/user-avatar.svg" alt="" />
        <input type="text" class="form-control" placeholder="Write a comment" />
      </div>
    </div>
    <div class="modal-background" id="enrlModal">
      <div class="modal-content enrl">
        <span class="close" onclick="hideModal('enrlModal')"></span>
        <h6>
          Fill the form to Enroll
        </h6>

        <form class="enroll-frm">
          <label for="fname">Title</label>
          <input type="text" id="fname-1" name="fname" />
          <label for="fname">Title</label>
          <input type="text" id="fname-2" name="fname" />
          <label for="fname">Title</label>
          <input type="text" id="fname-3" name="fname" />
          <label for="fname">Title</label>
          <input type="text" id="fname-4" name="fname" />

          <div class="feedback-btn">
            <button>Submit</button>
          </div>
        </form>
      </div>
    </div>
    <div class="modal-background" id="regModal">
      <div class="modal-content pop-02">
        <span class="close" onclick="hideModal('regModal')"></span>
        <h4>Please login to post</h4>
        <router-link to="/registration" tag="button" class="submit-btn5"
          >Okay
        </router-link>
        <router-link to="/" tag="button" class="submit-btn6"
          >Cancel
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

// axios.interceptors.response.use(function (response) {
//     return response;
// }, function (error) {
//     if (error.response.status !== 419) {
//         return Promise.reject(error)
//     }
//     //alert(error.response.status);
//     window.location.reload();
// });
export default {
  name: "Community",
  data: () => {
    return {
      searchKey: null,
      audio: null,
      disabled: true,
      host: null,
      msisdn: localStorage.getItem("phone")
        ? localStorage.getItem("phone")
        : "",
      token: localStorage.getItem("token") ? localStorage.getItem("token") : "",
      error: false,
      message: null,
      loading: true,
      posts: [],
      image: ""
    };
  },
  async created() {
    this.host = this.$root.url();
    try {
      const path = "community";
      let url = `${this.host}/${path}`;
      if (this.msisdn) {
        url += `?msisdn=${this.msisdn}`;
      }
      let response = await axios.get(url);
      if (response.data.success) {
        this.posts = response.data.posts;
        console.log(this.posts);
      } else {
        let errorObject = {
          status: true,
          message: "Something wrong, please try again later"
        };
        this.errorMessage(errorObject);
      }
      this.loading = false;
      return true;
    } catch (e) {
      let errorObject = {
        status: true,
        message: "Something wrong, please try again later"
      };
      this.errorMessage(errorObject);
      this.loading = false;
      return true;
    }
  },
  methods: {
    async uploadPost(e) {
      try {
        e.preventDefault();
        if (this.msisdn === "") {
          window.showModal("regModal");
          this.loading = false;
          return false;
        }
        console.log(this.image);
        let msg = e.target[0].value;
        let formData = new FormData();
        formData.append("msg", msg);
        formData.append("msisdn", this.msisdn);
        formData.append("image", this.image);
        this.host = this.$root.url();
        const path = "api/communityImageUpload";
        //const url = `${this.host}/${path}?msg=${msg}&msisdn=${this.msisdn}`;
        const url = `${this.host}/${path}`;
        let response = await axios.post(url, formData, {
          headers: {
            "X-Requested-With": "XMLHttpRequest"
          }
        });
        //console.log(response.data);
        if (response.data.success) {
          let errorObject = { status: false, message: "Successfully done" };
          this.errorMessage(errorObject);
          this.searchKey = null;
        } else {
          let errorObject = {
            status: true,
            message: "Something wrong, please try again later"
          };
          this.errorMessage(errorObject);
        }
        //  }
        // }
        this.loading = false;
        return true;
      } catch (error) {
        console.log(error);
        this.loading = false;
        return false;
      }
    },
    errorMessage(error) {
      this.error = error.status;
      this.message = error.message;
    },
    onImageChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createImage(files[0]);
    },
    createImage(file) {
      let reader = new FileReader();
      let vm = this;
      reader.onload = e => {
        vm.image = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  },
  watch: {
    searchKey: function(val) {
      this.disabled = !(val !== "");
    }
  }
};
</script>

<style scoped></style>
